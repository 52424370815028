.curve {
  color: white;
  position: relative;
  overflow: hidden;
  text-align: center;
  padding: 13rem 2rem 8rem 2rem;
  width: 100%;
  z-index: 2;
}

.curve::before {
  content: "";
  display: block;
  background: black;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  border-radius: 150vw;
  width: 300vw;
  height: 300vw;
  z-index: -1;
  -webkit-box-shadow: 0 15px 8.5px -0.5px #bababa;
-moz-box-shadow: 0 15px 8.5px -0.5px #bababa;
box-shadow: 0 15px 8.5px -0.5px #bababa;
  margin-bottom: 2rem;
}

strong {
  font-weight: calc(700 - 50);
}