.custom-pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
}

.custom-pagination li {
  font-size: 1rem;
}

.custom-pagination .Mui-selected {
  border-radius: 0.1875rem;
  background-color: #30AFBC !important;
  color: #fff;
}

.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root {
  border: 1px solid rgba(174, 174, 174, 0.475) !important;
  border-radius: .1875rem !important;
  min-width: 1rem !important;
  height: 1.3rem !important;
}

.css-1v2lvtn-MuiPaginationItem-root.ellipsis {
  display: inline !important;
}


.scroll-container::-webkit-scrollbar {
  width: 4px;
  background-color: #DBDBDB;
  height: 4px;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: #484848;
  border-radius: 10px;
}

.scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: #474747;
}

.container-fluid {
  width: 79vw !important;
}

.email-hover {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.email-hover:hover {
  white-space: normal;
}

.moveRight {
  animation: moveRight 1.5s ease-in-out;
}

@keyframes moveRight {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}


[class^=swal2] {
  -webkit-tap-highlight-color: rgb(0 0 0 / 65%) !important;
}

.swal2-container::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: -1;
  filter: blur(50px);
}

.swal2-popup {
  background: #232323;
}

div:where(.swal2-icon).swal2-success [class^=swal2-success-circular-line][class$=left] {
  background: #232323;
}

div:where(.swal2-container) h2:where(.swal2-title) {
  color: rgb(228, 228, 228);
  font-size: 1.1em;
  font-weight: bolder;
  font-family: 'Inter', sans-serif;
}

div:where(.swal2-container) button:where(.swal2-styled) {
  margin: 0.3125em;
  padding: 5px 77px;
  transition: box-shadow .1s;
  box-shadow: 0 0 0 3px rgb(213 213 213 / 39%);
  font-weight: 500;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  border: 0 !important;
  border-radius: 0.25em;
  background: initial;
  background-color: #1072ad !important;
  color: #d1d1d1;
  font-size: 1em;
}

div:where(.swal2-container) h2:where(.swal2-title) {
  padding: 0;
}

div:where(.swal2-container) button:where(.swal2-styled) {
  box-shadow: none;
}

@media (max-width: 600px) {
.respons{
  width: 80vw;
}
}

div:where(.swal2-container) .swal2-html-container {
  color: rgb(186, 186, 186);
  font-family: 'K2D';
}