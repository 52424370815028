.home-faq {
  padding: 4rem 10rem;
}

.row-title-text {
  font-size: 17px;
  font-weight: 600;
  padding-top: 10px;
  color: #131212d8;
}

.row-content-text {
  margin: 10px 0;
}

.text{
  font-size: 2rem;
}

.head{
  margin-bottom: 2rem;
}

.head-text{
  font-size: 1.2rem;
  color: #999;
}
.head-text1{
  font-size: 2rem;
  font-weight: 500;
}
@media (max-width: 844px) {
  .home-faq {
    padding: 4rem;
  }
}