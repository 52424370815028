.boxtoselect {
    height: 270px;
    width: 270px;
}

@media (max-width: 1585px) {
    .respo {
        right: 5px;
    }
}

@media (max-width: 1445px) {
    .respo {
        right: 0;
    }

    .comphead {
        font-size: 26px;
    }

    .company {
        max-width: 500px;
        padding-bottom: 2rem;
    }

    .colorbox {
        height: 50px;
        width: 50px;
    }
}

@media (max-width: 1360px) {
    .cont {
        width: 60%;
    }
}

@media (max-width: 1157px) {
    .cont {
        width: 65%;
    }

    .respo {
        margin-bottom: 5rem;
        overflow: auto;
        height: 170rem;
        font-size: 18px;
    }

    .comphead {
        font-size: 26px;
    }

    .company {
        max-width: 500px;
        padding-bottom: 2rem;
    }

    .boxtoselect {
        height: 170px;
        width: 170px;
    }

    .colorbox {
        height: 50px;
        width: 50px;
    }

    .subscription {
        font-size: small;
    }
}

@media (max-width: 450px) {

    .respo {
        padding-top: 4.5rem;
        position: relative;
        height: max-content;
    }

    .home h1 {
        padding: 0%;
    }

    .boxtoselect {
        margin: 0%;
        padding-bottom: 5%;
    }
}

.policy-input,
.policy-textarea {
    border-bottom: 1px solid black;
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}